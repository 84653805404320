import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Image = ({ url, webpUrl, position }) => {
  return (
    <div
      data-testid="teaser_image"
      className={cn(styles.image, { [styles[position]]: position }, { [styles.center]: !position })}
    >
      <picture>
        <source type="image/webp" srcSet={webpUrl} />
        <img srcSet={url} decoding="auto" alt="" loading="lazy" />
      </picture>
    </div>
  );
};

Image.defaultProps = {
  url: null,
  webpUrl: null,
  position: null,
};

Image.propTypes = {
  url: PropTypes.string,
  webpUrl: PropTypes.string,
  position: PropTypes.string,
};

export default Image;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Heading = ({ children, level }) => {
  const HeaderTag = `h${[1, 2, 3, 4, 5, 6][level - 1] || 2}`;
  return (
    <HeaderTag data-testid="teaser_heading" className={styles.header} dangerouslySetInnerHTML={{ __html: children }} />
  );
};

Heading.defaultProps = {
  children: '',
  level: 2,
};

Heading.propTypes = {
  children: PropTypes.string,
  level: PropTypes.number,
};

export default Heading;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const BetButton = ({ href, text }) =>
  href &&
  text && (
    <a className={styles.button} target="blank" href={href} data-testid="bet_button">
      {text}
    </a>
  );

BetButton.defaultProps = {
  href: '',
  text: '',
};

BetButton.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
};

export default BetButton;

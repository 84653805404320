import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const AppStoreButtons = ({ iosHref, androidHref, isUK }) => {
  const renderButton = (href, title, style, ukStyle, testId) => {
    if (!href) return null;

    return (
      <a
        data-testid={testId}
        title={title}
        className={cn(styles.button, { [style]: !isUK, [ukStyle]: isUK })}
        href={href}
      >
        <></>
      </a>
    );
  };

  return (
    (iosHref || androidHref) && (
      <div data-testid="app_store_buttons">
        {renderButton(iosHref, 'Download iOS app', styles.ios, styles.iosUk, 'ios_button')}
        {renderButton(androidHref, 'Download Android app', styles.android, styles.androidUk, 'android_button')}
      </div>
    )
  );
};

AppStoreButtons.defaultProps = {
  iosHref: '',
  androidHref: '',
  isUK: false,
};

AppStoreButtons.propTypes = {
  iosHref: PropTypes.string,
  androidHref: PropTypes.string,
  isUK: PropTypes.bool,
};

export default AppStoreButtons;

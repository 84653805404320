import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const RowWrapper = ({ children }) => <div data-testid="teaser_text_content">{children}</div>;
RowWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
const Text = ({ children }) => {
  return (
    <section className={styles.text} data-testid="teaser_text">
      {children(RowWrapper)}
    </section>
  );
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Text;
